import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { LocalStorageService } from './local-storage.service'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { ApiService } from 'src/app/core/abstracts/api.service'
import { Auth, AuthUser } from 'src/app/core/interfaces/auth'
import { AuthToken } from 'src/app/core/interfaces/auth-token'
import { CoreService } from 'src/app/core/services/core.service'
import { DiiaSignDeeplinkModel, DiiaSignStatusModel } from 'src/app/features/login/shared/types'

export type MockRole =
  | 'admin'
  | 'user'
  | 'companyBoss'
  | 'companyPerson'
  | 'companyWorker'
  | 'diiaBoss'
  | 'diiaPerson'
  | 'diiaWorker'
  | 'diiaAdmin'
  | null

@Injectable({
  providedIn: 'root',
})
export abstract class AuthService extends ApiService {
  auth?: AuthToken
  authUser?: AuthUser

  private mockRole$: BehaviorSubject<MockRole> = new BehaviorSubject<MockRole>(null)
  private mockAdditionalRole: BehaviorSubject<MockRole> = new BehaviorSubject<MockRole>(null)
  mockAdditionalRole$: Observable<MockRole> = this.mockAdditionalRole.asObservable()
  isAuth$: Observable<boolean> = this.mockRole$.asObservable().pipe(map((role) => !!role))

  protected constructor(
    override http: HttpClient,
    override coreService: CoreService,
    protected storageService: LocalStorageService,
    private router: Router,
  ) {
    super()

    if (this.coreService.isBrowser()) {
      const savedRole: MockRole = <MockRole>localStorage.getItem('mockRole') ?? null
      const saveAdditionaldRole: MockRole = <MockRole>localStorage.getItem('mockAdditionalRole') ?? null

      this.mockRole$.next(savedRole)
      this.mockAdditionalRole.next(saveAdditionaldRole)
    }

    this.mockRole$.subscribe((role) => {
      if (!role) {
        this.logout()
      }
    })
  }

  setMockRole(role: MockRole): void {
    this.mockRole$.next(role)
    localStorage.setItem('mockRole', role ?? '')
  }

  setMockAdditionalRole(role: MockRole): void {
    this.mockAdditionalRole.next(role)
    localStorage.setItem('mockAdditionalRole', role ?? '')
  }

  getCurrentMockRole(): MockRole {
    return this.mockRole$.value
  }

  getCurrentAdditionalMockRole(): MockRole {
    return this.mockAdditionalRole.value
  }

  isExpired(): boolean {
    if (!this.auth) {
      return false
    }

    const expiry = JSON.parse(atob(this.auth.token.split('.')[1])).exp

    return Math.floor(new Date().getTime() / 1000) >= expiry - 10
  }

  currentAuth(): Auth {
    if (!this.auth) {
      this.auth = <Auth>this.storageService.getItem('auth')
    }

    return this.auth
  }

  /**
   * Returns the current user
   */
  currentUser(reload?: boolean): Observable<AuthUser | undefined> {
    if (this.authUser && !reload) {
      return of(this.authUser)
    }

    return this.currentUserFetch()
  }

  currentUserFetch(): Observable<AuthUser | undefined> {
    return this.http.get<{ data: AuthUser }>(`${this.baseUrl}/user/me`).pipe(
      map((response) => {
        this.authUser = response.data

        return this.authUser
      }),
      catchError(() => {
        this.logout()

        return of(undefined)
      }),
    )
  }

  /**
   * Performs the login auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string): Observable<Auth> {
    return this.http.post<{ data: Auth }>(`${this.baseUrl}/auth/login`, { email, password }).pipe(
      map((response) => {
        if (response && response.data && response.data.token) {
          this.auth = response.data
          this.storageService.setItem('auth', this.auth)
        }

        return response.data
      }),
    )
  }

  refresh(): Observable<AuthToken> {
    return this.http.post<{ data: AuthToken }>(`${this.baseUrl}/auth/token/refresh`, {}).pipe(
      map((response) => {
        if (response?.data?.token) {
          this.auth = response.data
          this.storageService.setItem('auth', this.auth)
        }

        return response.data
      }),
    )
  }

  /**
   * Logout the user
   */
  logout(): void {
    // remove user from session storage to log user out
    this.storageService.removeItem('auth')
    this.storageService.removeItem('statsFilters')
    this.authUser = undefined
    this.auth = undefined
    // this.router.navigate([''])
  }

  getDiiaSignDeeplink(): Observable<DiiaSignDeeplinkModel> {
    return this.http.get<DiiaSignDeeplinkModel>(`${this.baseUrl}/auth/diia-sign/link`).pipe(
      map((data: DiiaSignDeeplinkModel) => {
        return DiiaSignDeeplinkModel.fromJson(<DiiaSignDeeplinkModel>data)
      }),
    )
  }

  getDiiaSignStatus(requestId: string, sessionToken: string): Observable<string> {
    const encodedRequestId = encodeURIComponent(requestId);
    return this.http
      .get<DiiaSignStatusModel>(`${this.baseUrl}/auth/diia-sign/status?requestId=${encodedRequestId}&sessionToken=${sessionToken}`)
      .pipe(
        map((data: DiiaSignStatusModel) => {
          return DiiaSignStatusModel.fromJson(<DiiaSignStatusModel>data).status;
        })
      );
  }
}
