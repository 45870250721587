import { Model } from '@app/core/abstracts/model'

export class DiiaSignDeeplinkModel extends Model {
    deeplink!: string;
    qr!: string;
    requestId!: string;
    sessionToken!: string;
}

export class DiiaSignStatusModel extends Model {
    status!: string;
}
